import "./Dropzone.css";
import { Fragment, useEffect, useState } from "react";
import { FileUpload } from "./FileUpload";
import {VoidButton} from "../Shared/VoidButton";

export function Dropzone() {
  let [files, setFiles] = useState<Array<File>>([]);
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState("");
  const [uploaded, setUploaded] = useState(false);

  function selectFiles() {
    let i = document.createElement("input");
    i.setAttribute("type", "file");
    i.setAttribute("multiple", "");
    i.addEventListener("change", function (evt) {
      if (evt.target && "files" in evt.target) {
        setFiles(evt.target.files as Array<File>);
      }
    });
    i.click();
  }

  function dropFiles(e: DragEvent | ClipboardEvent) {
    e.preventDefault();
    e.stopPropagation();
    if ("dataTransfer" in e && (e.dataTransfer?.files?.length ?? 0) > 0) {
      setFiles([...e.dataTransfer!.files]);
    } else if (
      "clipboardData" in e &&
      (e.clipboardData?.files?.length ?? 0) > 0
    ) {
      setFiles([...e.clipboardData!.files]);
    }
  }

  function copyAllLinks() {
    const links = document.querySelectorAll('a.fileLink');

    if (links.length < 1) {
      setError("Upload not done successfully for all files");
      return;
    }

    let allLinks = '';
    const host = window.location.origin;

    links.forEach(linkElement => {
      const relativeLink = linkElement.getAttribute('href');
      const fullLink = `${host}${relativeLink}`;
      allLinks += fullLink + '\n';
    });

    navigator.clipboard.writeText(allLinks).then(() => {
      setCopied(true);
      setError("");
    });
  }

  function uploadAllFiles() {
    const uploadButtons = document.querySelectorAll<HTMLElement>('.status .btn');

    uploadButtons.forEach(uploadElement => {
      uploadElement.click();
    });
    
    setUploaded(true);
  }

  function renderUploads() {
    let fElm = [];
    for (let f of files) {
      fElm.push(<FileUpload file={f} key={f.name} />);
    }
    if (files.length > 1) {
      fElm.push(!uploaded ? (
              <VoidButton onClick={uploadAllFiles} key="upload-all-files">
                Upload All Files
              </VoidButton>
          ) : null
      )
      
      fElm.push(<VoidButton onClick={copied ? undefined : copyAllLinks} key="copy-all-links">
        {copied ? 'Copied!' : 'Copy All Links'}
      </VoidButton>
      )
      fElm.push(<div>{error && <div style={{ color: 'red' }}>{error}</div>}</div>)
    }
    return <Fragment>{fElm}</Fragment>;
  }

  function renderDrop() {
    return (
      <div className="drop" onClick={selectFiles}>
        <div>
          Click me!
          <small>Or drop files here</small>
        </div>
      </div>
    );
  }

  useEffect(() => {
    document.addEventListener("paste", dropFiles);
    document.addEventListener("drop", dropFiles);
    document.addEventListener("dragover", dropFiles);
    return () => {
      document.removeEventListener("paste", dropFiles);
      document.removeEventListener("drop", dropFiles);
      document.removeEventListener("dragover", dropFiles);
    };
  }, []);

  return files.length === 0 ? renderDrop() : renderUploads();
}
