import "./FooterLinks.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { RootState } from "Store";

export function FooterLinks() {
  const profile = useSelector((s: RootState) => s.login.profile);

  return (
    <div className="footer">
      {profile?.roles?.includes("Admin") ? <a href="/admin">Admin</a> : null}
    </div>
  );
}
